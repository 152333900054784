<template>
  <div class="notification" v-if="!isMailVerified && isLoggedIn">
    Merci de vérifier votre adresse email afin d'accéder à l'application
  </div>
  <!-- <div class="notification" v-if="isMailVerified && !stripeRole">
    Choisissez une formule d'abonnement pour activer la veille sur votre espace
    <button><router-link to="/prices">Nos formules</router-link></button>
  </div> -->

  <div class="wrapper">
    <header>
      <div>
        <img src="@/assets/robot.png" class="logo" alt="" @click="goHome()" />
        <h1>
          Veille intelligente Marchés publics
          <span>{{ store.state.userInfos.company }}</span>
        </h1>
      </div>
      <nav>
        <button class="back" @click="goBack()">
          <font-awesome-icon icon="chevron-left" />
          <div>Retour</div>
        </button>
        <button v-if="isLoggedIn" @click="router.push('/opportunites')">
          <font-awesome-icon icon="user" />
          <div>Votre espace</div>
        </button>
        <button v-if="!isLoggedIn" @click="router.push('/login')">
          <!-- <font-awesome-icon icon="user" /> -->
          <div>Connexion</div>
        </button>
        <button v-if="!isLoggedIn" @click="router.push('/register')">
          <!-- <font-awesome-icon icon="user" /> -->
          <div>Inscription</div>
        </button>
        <button v-if="isLoggedIn" @click="router.push('/dashboard')">
          <font-awesome-icon icon="info" />
          <div>Vos infos</div>
        </button>
        <button @click="handleSignOut()" v-if="isLoggedIn">
          <font-awesome-icon icon="right-to-bracket" />
          <div>Deconnexion</div>
        </button>
      </nav>
    </header>
    <router-view />
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import store from "@/store/";

const router = useRouter();
const isLoggedIn = ref(false);
const isMailVerified = ref(false);
const stripeRole = ref("");

let auth;
onMounted(() => {
  console.log(router)
  auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      isLoggedIn.value = true;
    } else {
      isLoggedIn.value = false;
    }
    if (user && user.emailVerified) {
      isMailVerified.value = true;
      await store.dispatch("getCustomClaimRole");
      store.dispatch("realtimeRetrieveMarkets", auth.currentUser.uid);
      store.dispatch("retrieveInfos");
      stripeRole.value = store.state.stripeRole;
    } else {
      isMailVerified.value = false;
    }
    router.beforeEach(async (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        const currentUser = await auth.currentUser;

        if (currentUser) {
          if (await currentUser.emailVerified) {
            next();
          } else {
            next("/");
          }
        } else {
          next("/register");
        }
      } else {
        next();
      }
    });
  });
});

const handleSignOut = () => {
  signOut(auth).then(() => {
    router.push("/");
  });
};

const goBack = () => {
  router.go(-1);
};
const goHome = () => {
  router.push("/opportunites");
};
</script>
<style lang="scss">
.faded {
  opacity: 0.5;
}
select,
option {
  border: none;
  box-shadow: none;
  background: none;
  outline: none;
}

.logo {
  height: 110px;
  vertical-align: middle;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header h1 {
  display: inline-block;
  width: 180px;
  font-size: 16px;
  color: #667a8f;

  span {
    color: #4c8dd1;
  }
}
nav {
  @media (max-width: 450px) {
    position: fixed;
    z-index: 5;
    bottom: 0;
    width: 100%;
    background: #3574e7;
    display: flex;
    justify-content: center;

    button {
      border-radius: 0;
      border: 0;
      margin: 0;
      text-align: center;
      height: 4.2em;
      line-height: 1.2em;

      &.back {
        align-self: flex-start;
      }

      svg {
        display: block;
      }
    }
  }
  @media (min-width: 450px) {
    display: flex;

    button {
      background: #ffffff47;
      color: #5880ab;
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 15px -8px grey;

      &.back {
        display: none;
      }

      svg {
        margin-right: 5px;
      }
    }
  }
}

.blurred-button {
  background: #d5daff;
  color: #8187b9;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background: #e6edff;
  height: 100%;
}

h1 {
  font-size: 24px;
  padding-left: 3px;
}

a {
  text-decoration: none;
}

.wrapper {
  padding: 0 0 50px;
  max-width: 1050px;
  margin: 0 auto;
}

.relative {
  position: relative;
}
.highlight {
  color: #ff8137;
  font-size: 20px;
  font-weight: bold;
  padding: 0 5px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: 3b4a87;
  font-size: 0.8em;

  @media (max-width: 450px) {
    font-size: 0.9em;
  }
}

.submit {
  float: right;
}

.flex {
  display: flex;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-wrap-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.centered {
  justify-content: center;
}

input,
textarea,
select {
  border: 1px solid #dbdbdb;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

input[type="number"] {
  width: 50px;

  &.price {
    width: 65px;
  }
}

fieldset select {
  height: 3.2em;
  background: white;
  width: 97.1%;
  padding: 0 5%;
  border-radius: 10px;
  margin-top: 5px;
  color: #808d95;
}

form {
  //position: fixed;
  z-index: 1000;
  background: #eef0ff;
  padding: 0.5em;
  display: inline-block;
  min-width: 285px;
  width: 90%;
}

fieldset {
  max-width: 400px;
  background: #eef0ff;
  border: none;
  border-radius: 8px;
  padding: 0;
  margin: 0;

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  textarea {
    display: inline-block;
    line-height: 20px;
    height: 3.2em;
    margin-top: 5px;
    padding: 0 5%;
    width: 38%;
    border-radius: 8px;
    color: #064687;

    &.wide {
      width: 86.2%;
    }
  }
}

textarea {
  height: 60px;
  width: 90%;
  margin-bottom: 10px;
}

fieldset input[type="number"] {
  display: inline-block;
}

button,
.button {
  border: 1px solid #3574e7;
  cursor: pointer;
  border-radius: 5px;
  background: #3574e7;
  line-height: 3.2em;
  padding: 1px 16px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  margin: 0 2px;
  align-self: center;
  transition: all 0.3s;

  a {
    text-decoration: none;
  }

  &:hover {
    opacity: 0.5;
  }

  &.remove {
    color: #ff4e4e;
    background: #ffdfdf;
    border: 1px solid #f1a4a4;
  }

  &.edit {
    background: #a6c1ff;
    color: #ffffff;
  }

  &.cancel {
    background: #e1e1e1;
    color: grey;
  }

  a,
  & {
    color: #ffffff;
    text-decoration: none;
  }
  &.util {
    background: #9fe79f;
    color: #1f931f;
    border: 1px solid #78ad78;
  }
  &.basic {
    background: #efefef;
    color: #425581;
    border: 1px solid #babbbd;
  }
}

a.button.flat,
button.flat {
  display: inline-block;
  margin-bottom: 2px;
  line-height: normal;

  &.alt {
    background: #525153;
    color: #fa9cff;
    border-color: #e525ff;
  }
}
button.functionnal,
.button.functionnal {
  min-width: 65px;
  font-weight: bold;
  text-align: center;
}
.price {
  font-weight: bold;
}
.list-item {
  cursor: pointer;
  border-radius: 5px;
  margin: 15px;
  line-height: 20px;
  display: block;
  text-decoration: none;
  background: white;
  width: 320px;
  box-shadow: 0 0 21px -6px #afb6d7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s;

  &:hover {
    box-shadow: 0 0 21px -6px #54586d;
  }

  @media (max-width: 450px) {
    max-width: 97%;
  }

  .title {
    color: #3574e7;
    font-size: 1.2em;
  }

  .infos {
    padding: 0.5em 0 0.5em 1em;

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    li {
      list-style: none;
    }

    .observations {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 180px;
    }
  }
  .company {
    padding: 5px 5px 0 10px;
    color: #596093;
    font-weight: bold;
    text-align: right;
    font-size: 0.7rem;
  }
}
.add-button {
  position: fixed;
  top: 10px;
  right: 10px;
  border: 1px solid white;
  z-index: 1;
}
::placeholder {
  color: #808d95;
}
.bright {
  color: #c1c1c1;
}
.hidden {
  display: none;
}
.notification {
  background: #ffd7a5;
  padding: 25px;
  color: #6f331b;
  font-size: 15px;
  text-align: center;
}
.light-notif {
  background: #ffd7a5;
  color: red;
  font-size: 13px;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  a {
    color: red;
  }
}
//fonts
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 200 to 1000

.nunito {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.potentials {
  max-width: 900px;
  h3 {
    background: linear-gradient(45deg, #cfddff, transparent);
    margin: 7px 0 0 6px;
    padding: 10px;
    border-radius: 25px;
    color: #5b7dbb;
  }
}
input[type="checkbox"].control {
  display: inline-block;
}
.upblock {
  padding-top: 10px;
  text-align: center;

  .button.flat {
    width: 70%;
    margin-top: 5px;
  }
}
</style>
